//TODO: need to move all tokens to a provider, so that we can inject our token functions here
//http://stackoverflow.com/questions/15666048/service-vs-provider-vs-factory
angular.module('sq.user.auth.values', ['sq.config'])
.factory('AuthValues', AuthValuesFactory);

function AuthValuesFactory(SETTINGS) {
  var Values = {};

  Values.authBaseRoute = SETTINGS.apiEndpoint + 'user/auth/';
  Values.error = 'unauthorized';

  return Values;
}